//height_position	defocus_position	prime_beam_diameter	sub_beam_diameter	side_peak
export const demo_data_tsv = `
0	0	135.104202270508	134.800994873047	5.04869985580444
0	1	125.864402770996	127.005302429199	3.84179997444153
0	2	113.914901733398	112.021003723145	3.58260011672974
0	3	101.86979675293	70.7322006225586	11.2129001617432
0	4	84.3056030273438	66.8618011474609	8.10169982910156
0	5	71.2080993652344	64.6287994384766	7.00619983673096
0	6	65.6467971801758	63.4305000305176	6.32840013504028
0	7	62.9205017089844	62.9598007202148	5.70489978790283
0	8	62.3624992370605	62.907901763916	5.2217001914978
0	9	64.0223999023438	63.7098007202148	6.10599994659424
0	10	69.9906005859375	66.1075973510742	8.10550022125244
0	11	88.5914001464844	70.9975967407227	10.9807996749878
0	12	109.90779876709	97.2384033203125	3.46370005607605
0	13	120.218399047852	119.287300109863	4.17700004577637
0	14	131.858795166016	127.826499938965	3.68959999084473
0	15	154.873199462891	134.27409362793	1.85500001907349
0	16	175.586700439453	143.14599609375	0
1	0	137.788101196289	133.747604370117	0
1	1	124.309501647949	127.361801147461	4.09849977493286
1	2	111.807899475098	111.560699462891	3.45700001716614
1	3	98.902702331543	71.3876037597656	11.0914001464844
1	4	82.1429977416992	66.8515014648438	7.89860010147095
1	5	70.0009994506836	64.7218017578125	6.94040012359619
1	6	64.8358001708984	63.6568984985352	5.85589981079102
1	7	62.5303001403809	62.9226989746094	5.34140014648438
1	8	62.1759986877441	63.0531997680664	5.49830007553101
1	9	64.5361022949219	63.7803001403809	6.22609996795654
1	10	72.2313995361328	66.4107971191406	8.86410045623779
1	11	94.6017990112305	71.5095977783203	11.4586000442505
1	12	109.931198120117	96.3442993164063	3.02690005302429
1	13	120.550498962402	119.782402038574	3.87089991569519
1	14	133.516693115234	128.960800170898	1.6826000213623
1	15	160.941101074219	135.124298095703	1.65960001945496
1	16	177.130401611328	143.94709777832	0
2	0	137.315902709961	133.419799804688	4.8882999420166
2	1	123.107498168945	125.665100097656	3.41269993782043
2	2	110.181999206543	108.664596557617	3.02990007400513
2	3	96.8347015380859	69.7837982177734	3.34060001373291
2	4	84.2126998901367	66.7487030029297	6.85020017623901
2	5	71.2108993530273	64.6138000488281	6.57490015029907
2	6	64.9068984985352	63.4589004516602	5.39480018615723
2	7	61.9897003173828	63.032901763916	5.37629985809326
2	8	61.5036010742188	62.9026985168457	5.31650018692017
2	9	63.1383018493652	64.0546035766602	6.44430017471313
2	10	69.4161987304688	66.1789016723633	8.32499980926514
2	11	89.5813980102539	71.7447967529297	11.447699546814
2	12	109.45189666748	98.940803527832	3.53859996795654
2	13	120.573699951172	122.085800170898	4.08090019226074
2	14	134.651092529297	131.160095214844	5.11840009689331
2	15	161.201904296875	136.136703491211	1.62849998474121
2	16	177.718505859375	145.922500610352	0
3	0	140.201904296875	133.259399414063	4.71869993209839
3	1	127.551902770996	125.987396240234	0
3	2	114.899803161621	106.956298828125	2.97760009765625
3	3	101.555000305176	71.9838027954102	3.79819989204407
3	4	90.0935974121094	67.7146987915039	7.13019990921021
3	5	75.7115020751953	65.3150024414063	6.25979995727539
3	6	67.1400985717773	64.1801986694336	5.81909990310669
3	7	63.0886001586914	63.5941009521484	5.03779983520508
3	8	61.5046997070313	63.3329010009766	5.14589977264404
3	9	62.1212997436523	64.0637969970703	6.06409978866577
3	10	65.3878021240234	66.2898025512695	7.89389991760254
3	11	74.7294998168945	70.3377990722656	9.105299949646
3	12	101.122901916504	92.842903137207	3.18869996070862
3	13	115.029296875	120.141700744629	3.48399996757507
3	14	125.864097595215	129.221405029297	1.755499958992
3	15	144.333999633789	135.749206542969	0
3	16	167.720001220703	144.238998413086	0
4	0	137.480499267578	135.873397827148	0
4	1	124.557800292969	126.820098876953	0
4	2	113.069801330566	109.92960357666	5.08279991149902
4	3	100.233596801758	74.0285034179688	3.9293999671936
4	4	87.1031036376953	68.8918991088867	7.42500019073486
4	5	75.3648986816406	66.3619995117188	6.4903998374939
4	6	67.0807037353516	64.5850982666016	5.25559997558594
4	7	63.0578994750977	64.0800018310547	4.90070009231567
4	8	61.4901008605957	63.752498626709	4.84149980545044
4	9	62.0387992858887	64.2248001098633	5.71509981155396
4	10	64.6193008422852	65.6875991821289	6.63119983673096
4	11	73.0556030273438	68.7263031005859	8.07740020751953
4	12	101.963401794434	81.1026000976563	2.97679996490479
4	13	114.811302185059	113.545402526855	3.36310005187988
4	14	124.55069732666	125.962303161621	3.54520010948181
4	15	138.050598144531	134.279495239258	0
4	16	163.053894042969	140.724700927734	0
5	0	136.82080078125	135.444793701172	4.29850006103516
5	1	123.465400695801	127.614501953125	3.79209995269775
5	2	111.576202392578	113.209899902344	5.12160015106201
5	3	99.5180969238281	74.216796875	3.71919989585876
5	4	87.3102035522461	69.3874969482422	8.03629970550537
5	5	74.8062973022461	66.421501159668	6.12400007247925
5	6	67.7603988647461	65.005500793457	5.40990018844604
5	7	63.5525016784668	64.3202972412109	4.88000011444092
5	8	61.9099998474121	64.0495986938477	4.83990001678467
5	9	62.2434997558594	64.4484024047852	5.57369995117188
5	10	65.4520034790039	65.7542037963867	7.30270004272461
5	11	72.536003112793	68.8356018066406	8.08240032196045
5	12	96.3078994750977	78.3030014038086	2.32130002975464
5	13	110.97509765625	100.277801513672	2.68630003929138
5	14	121.26490020752	125.388702392578	0
5	15	132.80810546875	134.353897094727	0
5	16	157.865905761719	140.304397583008	1.95019996166229
6	0	129.686401367188	132.234298706055	0
6	1	117.946403503418	123.25129699707	6.53359985351563
6	2	104.851402282715	106.686096191406	5.18930006027222
6	3	91.3741989135742	74.1072006225586	9.83899974822998
6	4	79.5998001098633	69.519401550293	7.83489990234375
6	5	69.7537002563477	66.8337020874023	5.23820018768311
6	6	64.9044036865234	65.4802017211914	5.23290014266968
6	7	62.282398223877	64.472900390625	4.17350006103516
6	8	61.4509010314941	64.5931015014648	4.4539999961853
6	9	62.6407012939453	65.2768020629883	5.88910007476807
6	10	67.2975006103516	67.0426025390625	5.73449993133545
6	11	78.1417007446289	70.3487014770508	2.69479990005493
6	12	103.373100280762	84.4016036987305	3.03279995918274
6	13	114.757499694824	102.237899780273	3.28489995002747
6	14	124.086898803711	123.231597900391	3.58529996871948
6	15	139.34700012207	132.233505249023	4.3173999786377
6	16	165.937698364258	139.573699951172	5.01760005950928
7	0	125.894897460938	132.894805908203	0
7	1	116.679801940918	126.280899047852	4.46700000762939
7	2	105.135200500488	110.744102478027	3.16449999809265
7	3	90.6177978515625	73.6638031005859	3.17449998855591
7	4	77.9481964111328	69.3011016845703	7.73089981079102
7	5	68.8732986450195	67.2173004150391	6.65950012207031
7	6	64.1809997558594	65.2920989990234	5.43529987335205
7	7	62.0392990112305	64.3817977905273	5.05950021743774
7	8	61.5181007385254	64.0286026000977	5.3572998046875
7	9	62.787899017334	64.5810012817383	6.5612998008728
7	10	67.3489990234375	66.3949966430664	7.53350019454956
7	11	81.0756988525391	69.6109008789063	9.47550010681152
7	12	103.624198913574	75.3210983276367	2.46499991416931
7	13	115.079299926758	110.091201782227	0
7	14	123.68229675293	127.123901367188	1.21539998054504
7	15	139.557800292969	133.276901245117	0
7	16	168.896194458008	141.460006713867	0
8	0	122.975303649902	135.958297729492	0
8	1	111.093002319336	127.442497253418	3.87569999694824
8	2	100.355003356934	109.227401733398	2.56780004501343
8	3	87.4452972412109	72.806396484375	10.6572999954224
8	4	76.3106002807617	70.3060989379883	7.3685998916626
8	5	67.4912033081055	66.8124008178711	5.68069982528687
8	6	63.7799987792969	65.3004989624023	5.25939989089966
8	7	61.798900604248	64.3491973876953	4.78690004348755
8	8	61.6996994018555	64.1993026733398	5.25869989395142
8	9	63.3060989379883	64.7550964355469	6.15170001983643
8	10	68.4555969238281	66.220703125	7.52810001373291
8	11	83.4542007446289	69.4468002319336	9.70359992980957
8	12	103.272300720215	73.8442993164063	2.8371000289917
8	13	114.459503173828	101.017196655273	3.11120009422302
8	14	123.80419921875	123.677696228027	0
8	15	140.779098510742	132.353698730469	1.92379999160767
8	16	167.740600585938	140.658096313477	2.12649989128113
9	0	118.646797180176	133.57470703125	5.23969984054565
9	1	109.818702697754	124.627502441406	0
9	2	98.4225006103516	105.779800415039	0
9	3	83.7974014282227	73.2837982177734	9.94789981842041
9	4	71.690299987793	69.2848968505859	7.58139991760254
9	5	66.3824005126953	67.8422012329102	5.31529998779297
9	6	63.0068016052246	65.9430999755859	4.96360015869141
9	7	61.4502983093262	65.1573028564453	4.80800008773804
9	8	61.7309989929199	64.9213027954102	5.23430013656616
9	9	63.9387016296387	65.2177963256836	5.84959983825684
9	10	70.1429977416992	67.1193008422852	7.15460014343262
9	11	85.1593017578125	70.1865005493164	9.22599983215332
9	12	105.665100097656	74.415901184082	1.54579997062683
9	13	114.997703552246	94.4462966918945	1.78880000114441
9	14	123.546997070313	120.158699035645	0
9	15	142.302993774414	132.199295043945	1.85409998893738
9	16	161.592193603516	141.789505004883	2.10380005836487
10	0	112.78150177002	135.177505493164	4.87400007247925
10	1	104.214897155762	123.966499328613	0
10	2	91.7453994750977	101.92960357666	2.21700000762939
10	3	78.7046966552734	72.4433975219727	10.1738996505737
10	4	69.0075988769531	69.3407974243164	7.36920022964478
10	5	64.7419967651367	67.3808975219727	5.26609992980957
10	6	62.2099990844727	66.0036010742188	4.86630010604858
10	7	61.3880004882813	65.4141998291016	4.66760015487671
10	8	61.8804016113281	65.1391983032227	4.82600021362305
10	9	65.0839004516602	65.7722015380859	5.62939977645874
10	10	73.6726989746094	67.6231994628906	7.20459985733032
10	11	93.9365997314453	70.6425018310547	8.49619960784912
10	12	110.038200378418	74.500602722168	2.49830007553101
10	13	118.538101196289	96.2315979003906	2.60759997367859
10	14	127.586196899414	121.028099060059	1.89450001716614
10	15	154.641098022461	132.317901611328	1.76979994773865
10	16	174.671203613281	139.219299316406	4.68830013275146
11	0	111.48560333252	135.809097290039	0
11	1	102.871002197266	126.335899353027	0
11	2	91.5450973510742	107.649597167969	0
11	3	78.1934967041016	73.6261978149414	10.5937995910645
11	4	68.399299621582	69.6880035400391	7.87860012054443
11	5	64.4237976074219	67.6540985107422	6.18340015411377
11	6	62.0396995544434	65.7819976806641	5.21659994125366
11	7	61.2406005859375	65.2146987915039	4.91379976272583
11	8	62.0111999511719	64.8223037719727	5.17889976501465
11	9	64.676399230957	65.2845993041992	5.74399995803833
11	10	72.119499206543	66.772102355957	6.8011999130249
11	11	92.0954971313477	69.3856964111328	8.28919982910156
11	12	108.984596252441	73.6122970581055	2.45120000839233
11	13	117.096900939941	81.9685974121094	2.74489998817444
11	14	125.289497375488	116.602096557617	0
11	15	146.240600585938	130.269500732422	3.31419992446899
11	16	170.850296020508	137.645706176758	0
12	0	111.597503662109	133.662002563477	0
12	1	102.651000976563	123.979499816895	0
12	2	90.0273971557617	99.2472991943359	13.5179996490479
12	3	75.6417007446289	72.2366027832031	10.2727003097534
12	4	67.7524032592773	69.8395004272461	6.92040014266968
12	5	63.5573997497559	67.3352966308594	5.96420001983643
12	6	61.649299621582	65.9501037597656	5.33979988098145
12	7	61.1351013183594	65.2319030761719	5.02540016174316
12	8	61.9917984008789	65.6508026123047	5.04510021209717
12	9	65.5512008666992	66.1484985351563	6.31920003890991
12	10	73.3467025756836	67.8200988769531	6.90910005569458
12	11	96.1054992675781	70.9046020507813	8.46739959716797
12	12	108.655601501465	74.7647018432617	2.42820000648499
12	13	117.031402587891	87.0364990234375	2.59800004959106
12	14	126.296798706055	119.714500427246	3.07669997215271
12	15	148.920104980469	129.978897094727	0
12	16	172.014099121094	137.731994628906	0
13	0	110.983703613281	134.041900634766	0
13	1	100.861099243164	125.599601745605	0
13	2	89.7500991821289	106.78150177002	0
13	3	76.8498001098633	74.284797668457	10.0904998779297
13	4	68.6393966674805	70.1586990356445	7.46700000762939
13	5	64.3080978393555	68.1132965087891	6.26730012893677
13	6	61.9835014343262	66.2198028564453	5.35809993743896
13	7	61.1097984313965	65.3936004638672	5.17110013961792
13	8	61.6834983825684	65.3447036743164	5.18020009994507
13	9	64.5634994506836	65.6766967773438	5.6778998374939
13	10	71.0791015625	67.3870010375977	6.89940023422241
13	11	90.9008026123047	69.7606964111328	7.80749988555908
13	12	108.573997497559	73.1995010375977	9.15890026092529
13	13	117.163398742676	83.9924011230469	2.54450011253357
13	14	127.096801757813	118.860900878906	3.14159989356995
13	15	147.144897460938	130.823303222656	3.37739992141724
13	16	164.606796264648	137.820007324219	3.81329989433289
14	0	112.119400024414	133.681396484375	0
14	1	102.76439666748	123.955596923828	3.1061999797821
14	2	89.7816009521484	96.4294967651367	0
14	3	76.2716979980469	72.5273971557617	10.2428998947144
14	4	68.9505004882813	70.3723983764648	7.2746000289917
14	5	64.3295974731445	67.7227020263672	6.59259986877441
14	6	61.9940986633301	66.0727005004883	5.49919986724854
14	7	61.1278991699219	65.6744003295898	5.23250007629395
14	8	61.6292991638184	65.4441986083984	5.36140012741089
14	9	64.0173034667969	66.0380020141602	6.36649990081787
14	10	69.7637023925781	67.5313034057617	7.41520023345947
14	11	86.7518005371094	70.3552017211914	9.79590034484863
14	12	106.872802734375	74.5698013305664	11.3894996643066
14	13	115.820198059082	89.5880966186523	2.79819989204407
14	14	124.281097412109	121.101303100586	3.16240000724792
14	15	141.370407104492	131.299499511719	3.62840008735657
14	16	165.302993774414	139.724502563477	4.94689989089966
15	0	106.137802124023	133.641204833984	0
15	1	95.5613021850586	124.180397033691	2.92610001564026
15	2	80.3986968994141	82.5103988647461	2.46799993515015
15	3	70.9922027587891	72.7104034423828	9.5088996887207
15	4	65.6580963134766	69.5	6.78940010070801
15	5	62.8125991821289	67.9224014282227	6.37510013580322
15	6	61.2141990661621	66.5522994995117	5.02360010147095
15	7	60.80419921875	65.9495010375977	4.92059993743896
15	8	61.9396018981934	66.0447006225586	5.14599990844727
15	9	65.3088989257813	66.1512985229492	6.02880001068115
15	10	74.4389038085938	68.1116027832031	7.21099996566772
15	11	97.1489028930664	70.2948989868164	8.37979984283447
15	12	113.577697753906	74.2313003540039	9.88759994506836
15	13	121.639099121094	82.575798034668	0.903900027275085
15	14	132.368698120117	118.750198364258	3.24300003051758
15	15	158.769607543945	129.949996948242	3.74219989776611
15	16	171.06950378418	137.087997436523	4.16540002822876
16	0	105.962997436523	133.566101074219	0
16	1	94.5167007446289	120.87809753418	0
16	2	81.0671997070313	95.8917999267578	2.6168999671936
16	3	70.688102722168	72.6376037597656	9.88210010528564
16	4	65.4649963378906	69.7004013061523	2.39869999885559
16	5	62.540699005127	67.6392974853516	5.52930021286011
16	6	60.8554000854492	66.1557998657227	4.89879989624023
16	7	60.736400604248	65.6456985473633	4.72910022735596
16	8	61.915901184082	65.4501037597656	4.96000003814697
16	9	65.3795013427734	65.9781036376953	5.7733998298645
16	10	74.6038970947266	67.4502029418945	6.74329996109009
16	11	100.67790222168	70.5628967285156	8.39169979095459
16	12	112.039596557617	75.2052001953125	10.3421001434326
16	13	119.458396911621	85.184196472168	2.96670007705688
16	14	130.457305908203	120.670097351074	3.26399993896484
16	15	161.515502929688	130.462707519531	3.35249996185303
16	16	176.277099609375	138.412704467773	3.89450001716614
17	0	108.418197631836	134.316192626953	0
17	1	98.3352966308594	123.576103210449	0
17	2	84.8480987548828	97.3175964355469	0
17	3	75.0866012573242	72.6115036010742	10.242600440979
17	4	68.0597991943359	69.5342025756836	7.4556999206543
17	5	64.0867004394531	67.3503036499023	6.81839990615845
17	6	61.8600006103516	66.3955993652344	5.43590021133423
17	7	61.0974006652832	65.3505020141602	5.09800004959106
17	8	61.8050994873047	65.2752990722656	5.06720018386841
17	9	64.0990982055664	65.8766021728516	6.01590013504028
17	10	70.7642974853516	67.560302734375	7.48379993438721
17	11	85.6261978149414	69.8342971801758	8.20289993286133
17	12	108.973297119141	74.1818008422852	2.33730006217957
17	13	117.347297668457	83.2298965454102	2.85989999771118
17	14	125.721199035645	119.093101501465	2.96810007095337
17	15	145.658706665039	130.104507446289	3.54500007629395
17	16	160.046997070313	137.469696044922	3.85859990119934
18	0	110.187202453613	133.569305419922	0
18	1	98.4525985717773	126.01490020752	0
18	2	83.7967987060547	96.0791015625	0
18	3	73.2570037841797	73.6183013916016	9.65629959106445
18	4	67.8496017456055	69.7390975952148	8.19369983673096
18	5	64.0202026367188	67.8296966552734	6.66699981689453
18	6	62.263599395752	66.4777984619141	5.62440013885498
18	7	61.3978004455566	65.5037002563477	5.43959999084473
18	8	61.7402000427246	65.1504974365234	5.14289999008179
18	9	64.048698425293	66.084098815918	5.97319984436035
18	10	69.7126998901367	66.8712997436523	7.9702000617981
18	11	83.2838973999023	68.9044036865234	7.41569995880127
18	12	109.851898193359	72.7460021972656	9.00759983062744
18	13	117.794898986816	81.6085968017578	2.49489998817444
18	14	126.897003173828	118.464797973633	3.23850011825562
18	15	148.569900512695	128.800201416016	3.49790000915527
18	16	170.466094970703	136.930801391602	0
19	0	111.339698791504	133.838897705078	0
19	1	100.696502685547	125.334602355957	0
19	2	87.0888977050781	105.766296386719	14.2489995956421
19	3	73.1313018798828	74.2863998413086	10.4343996047974
19	4	66.9493026733398	70.0012969970703	7.81960010528564
19	5	63.673999786377	67.9290008544922	6.81920003890991
19	6	61.6349983215332	66.6414031982422	5.73939990997314
19	7	61.3450012207031	65.7934036254883	5.84240007400513
19	8	61.9738006591797	65.4340972900391	5.4717001914978
19	9	65.0683975219727	65.8948974609375	6.39650011062622
19	10	72.4169006347656	67.477897644043	7.37919998168945
19	11	89.9028015136719	69.1929016113281	8.09440040588379
19	12	110.989196777344	72.3884963989258	2.66829991340637
19	13	119.345100402832	79.7345962524414	2.7179000377655
19	14	128.863403320313	118.741798400879	2.92779994010925
19	15	154.805099487305	128.896606445313	3.44059991836548
19	16	170.222198486328	134.617294311523	1.56529998779297
20	0	114.007598876953	135.599197387695	0
20	1	102.822998046875	125.64990234375	0
20	2	87.0307006835938	102.599998474121	2.51799988746643
20	3	74.6233978271484	74.3307037353516	10.2934999465942
20	4	67.9692001342773	70.6837005615234	8.34609985351563
20	5	63.9906997680664	67.8163986206055	6.43599987030029
20	6	62.0826988220215	66.3175964355469	5.75050020217896
20	7	61.3353004455566	65.383903503418	5.16620016098022
20	8	62.0690002441406	65.2577972412109	5.29860019683838
20	9	64.5297012329102	65.822998046875	6.14879989624023
20	10	70.6331024169922	66.6235961914063	6.66540002822876
20	11	87.2266998291016	68.6430969238281	8.02070045471191
20	12	109.246696472168	72.2106018066406	2.28379988670349
20	13	118.142501831055	80.9471969604492	3.05679988861084
20	14	127.198097229004	116.199501037598	2.72530007362366
20	15	147.405807495117	128.773101806641	4.30859994888306
20	16	170.553894042969	136.139999389648	0
21	0	115.106597900391	133.222503662109	0
21	1	105.284103393555	123.742599487305	0
21	2	90.9316024780273	106.676498413086	13.4959001541138
21	3	75.6112976074219	75.5050964355469	10.4308004379272
21	4	68.1828002929688	71.2393035888672	7.65549993515015
21	5	64.0466995239258	68.1335983276367	6.01590013504028
21	6	61.739200592041	66.7897033691406	4.99900007247925
21	7	61.0978012084961	65.7445983886719	4.72049999237061
21	8	61.9081001281738	65.3025970458984	5.0753002166748
21	9	64.8453979492188	65.7484970092773	5.83680009841919
21	10	71.3554992675781	67.4782028198242	6.71470022201538
21	11	84.9558029174805	69.6511001586914	8.08549976348877
21	12	109.624702453613	73.5675964355469	2.18499994277954
21	13	117.51490020752	81.3984985351563	2.22900009155273
21	14	127.889099121094	112.426300048828	2.57109999656677
21	15	149.953994750977	126.366203308105	1.24800002574921
21	16	163.442901611328	133.823303222656	1.83000004291534
22	0	116.923301696777	133.493896484375	0
22	1	106.481002807617	122.710296630859	0
22	2	90.3331985473633	98.2753982543945	0
22	3	75.3255004882813	74.0173034667969	9.54590034484863
22	4	68.7185974121094	70.247802734375	7.10960006713867
22	5	64.3030014038086	68.0401000976563	5.48220014572144
22	6	62.0413017272949	66.738899230957	4.46950006484985
22	7	61.3469009399414	65.5248031616211	4.7202000617981
22	8	62.2528991699219	65.2378005981445	4.86329984664917
22	9	65.2839965820313	65.8234024047852	5.63789987564087
22	10	71.8930969238281	67.4712982177734	6.47580003738403
22	11	91.010498046875	71.3031997680664	0
22	12	109.197799682617	76.4141006469727	2.37129998207092
22	13	118.722900390625	97.0362014770508	2.33459997177124
22	14	127.489898681641	121.671897888184	0
22	15	148.826797485352	129.638702392578	0
22	16	169.760299682617	137.145202636719	0
23	0	121.884803771973	132.855102539063	0
23	1	112.379096984863	123.364097595215	0
23	2	98.8519973754883	102.929397583008	0
23	3	82.2695999145508	74.5318984985352	9.4350004196167
23	4	71.4178009033203	70.1740036010742	7.80660009384155
23	5	65.572998046875	67.6144027709961	6.12960004806519
23	6	62.7779998779297	66.1669998168945	5.1314001083374
23	7	61.4576988220215	65.1537017822266	4.60669994354248
23	8	61.8893013000488	64.8207015991211	4.94840002059937
23	9	64.1887969970703	65.1869964599609	5.88040018081665
23	10	69.198600769043	66.6199035644531	6.66830015182495
23	11	84.5693969726563	70.2413024902344	1.03250002861023
23	12	107.139701843262	74.6973037719727	2.63969993591309
23	13	116.636901855469	98.0992965698242	1.19079995155334
23	14	124.912399291992	122.033798217773	0.960399985313416
23	15	145.788101196289	131.076797485352	0
23	16	161.871597290039	138.056106567383	1.77359998226166
24	0	122.73509979248	129.178604125977	0
24	1	111.551597595215	117.676902770996	0
24	2	98.4310989379883	88.0786972045898	0
24	3	81.6110992431641	74.7470016479492	8.61250019073486
24	4	69.8715972900391	70.0794982910156	6.80350017547607
24	5	64.5464019775391	67.5028991699219	5.41270017623901
24	6	62.0057983398438	66.2351989746094	4.43389987945557
24	7	61.0021018981934	65.6429977416992	4.43739986419678
24	8	61.5186996459961	65.2412033081055	4.67399978637695
24	9	64.0267028808594	65.703498840332	5.74370002746582
24	10	69.9374008178711	67.8011016845703	6.16260004043579
24	11	87.2261962890625	71.6019973754883	2.55040001869202
24	12	108.212501525879	81.8205032348633	2.70910000801086
24	13	117.633201599121	103.433097839355	2.85139989852905
24	14	128.681304931641	125.58910369873	3.06909990310669
24	15	153.30810546875	131.725204467773	3.62019991874695
24	16	174.428100585938	138.980102539063	4.92049980163574
25	0	126.71720123291	131.83659362793	0
25	1	115.798500061035	122.739501953125	0
25	2	102.608497619629	106.679298400879	2.6596999168396
25	3	86.4928970336914	73.5543975830078	9.82089996337891
25	4	72.4971008300781	69.6764984130859	7.27710008621216
25	5	65.5374984741211	66.9357986450195	5.8225998878479
25	6	62.3129997253418	65.7686004638672	4.77860021591187
25	7	61.171199798584	64.9076995849609	4.42649984359741
25	8	61.7179985046387	65.2230987548828	4.95550012588501
25	9	63.8391990661621	65.7720031738281	5.74529981613159
25	10	70.0454025268555	67.7380981445313	7.38600015640259
25	11	83.9079971313477	71.3046035766602	8.26119995117188
25	12	106.668296813965	77.5369033813477	2.75169992446899
25	13	117.874603271484	101.699798583984	1.41989994049072
25	14	127.467903137207	124.609397888184	1.49820005893707
25	15	150.374298095703	132.122497558594	1.98710000514984
25	16	173.871597290039	138.835296630859	2.46140003204346
26	0	129.078002929688	132.420196533203	0
26	1	117.485000610352	121.643600463867	0
26	2	104.660003662109	103.369003295898	2.35730004310608
26	3	87.072998046875	73.9250030517578	9.15540027618408
26	4	72.9598999023438	69.1719970703125	6.83930015563965
26	5	66.0587997436523	66.7602996826172	5.59259986877441
26	6	62.8079986572266	65.486701965332	4.7172999382019
26	7	61.5689010620117	64.8203964233398	4.67539978027344
26	8	61.6851997375488	64.6965026855469	5.30229997634888
26	9	63.684700012207	65.0606994628906	5.88329982757568
26	10	69.940803527832	67.1869964599609	6.49650001525879
26	11	84.396598815918	70.2578964233398	7.66120004653931
26	12	107.32389831543	80.2451019287109	2.48779988288879
26	13	117.351501464844	103.408798217773	1.10689997673035
26	14	127.242202758789	125.394302368164	0
26	15	150.7333984375	131.310592651367	0
26	16	177.130004882813	139.786804199219	0
27	0	129.600204467773	133.31559753418	0
27	1	118.97339630127	125.083503723145	0
27	2	106.627502441406	111.127502441406	0
27	3	90.9626007080078	74.323600769043	9.63840007781982
27	4	76.0597991943359	69.5126037597656	7.36899995803833
27	5	67.0810012817383	66.2871017456055	5.96600008010864
27	6	63.4581985473633	65.0949020385742	5.39879989624023
27	7	61.6235008239746	63.8512992858887	5.22289991378784
27	8	61.7429008483887	63.9407997131348	5.63009977340698
27	9	64.7292022705078	64.970100402832	7.30600023269653
27	10	71.2165985107422	66.9459991455078	8.7278995513916
27	11	86.9045028686523	70.0726013183594	0
27	12	106.867897033691	77.3192977905273	2.79629993438721
27	13	117.223701477051	105.827201843262	2.76090002059937
27	14	127.598899841309	124.846199035645	0
27	15	152.151702880859	132.179306030273	0
27	16	170.493896484375	140.236206054688	2.16059994697571
28	0	129.446701049805	133.156799316406	0
28	1	116.019500732422	122.103103637695	0
28	2	101.037399291992	92.1194000244141	0
28	3	86.631103515625	73.1125030517578	9.20890045166016
28	4	71.2387008666992	68.1826019287109	7.41680002212524
28	5	65.2233963012695	66.0139999389648	5.70949983596802
28	6	62.1817016601563	64.5811996459961	4.89099979400635
28	7	60.9776992797852	63.8320007324219	5.01240015029907
28	8	61.6645011901855	63.779598236084	5.15530014038086
28	9	65.0992965698242	64.902099609375	6.95459985733032
28	10	72.0860977172852	66.7151031494141	8.05099964141846
28	11	94.72509765625	71.2162017822266	2.70740008354187
28	12	111.981903076172	90.8299026489258	2.64429998397827
28	13	122.336601257324	113.21369934082	3.00999999046326
28	14	134.575302124023	126.075897216797	4.15439987182617
28	15	165.083099365234	134.082794189453	5.33640003204346
28	16	185.377807617188	140.653198242188	0
29	0	123.702201843262	133.966598510742	0
29	1	111.78800201416	125.739196777344	0
29	2	97.8231964111328	109.469596862793	12.9441995620728
29	3	82.3208999633789	72.4918975830078	9.94750022888184
29	4	71.2396011352539	67.2177963256836	7.57280015945435
29	5	65.5470962524414	64.9804000854492	6.12480020523071
29	6	62.4373016357422	63.8386001586914	5.25019979476929
29	7	61.5914993286133	63.0829010009766	5.3621997833252
29	8	62.496898651123	62.9001007080078	5.57250022888184
29	9	66.7202987670898	63.8373985290527	7.14090013504028
29	10	77.5005035400391	65.7314987182617	9.20950031280518
29	11	102.323501586914	69.4916000366211	11.2424001693726
29	12	118.291198730469	93.8544998168945	3.32200002670288
29	13	129.694107055664	120.048400878906	3.77169990539551
29	14	149.446395874023	127.080497741699	3.82879996299744
29	15	174.541000366211	134.789703369141	0
29	16	187.154205322266	143.716995239258	1.94599997997284
30	0	124.378799438477	136.28239440918	0
30	1	110.118202209473	127.523696899414	0
30	2	97.2003021240234	111.769599914551	13.2666997909546
30	3	81.6313018798828	72.6573028564453	9.59930038452148
30	4	70.4213027954102	68.0302963256836	7.54390001296997
30	5	65.0211029052734	65.3948974609375	6.69220018386841
30	6	62.2658004760742	64.1513977050781	5.25250005722046
30	7	61.4626007080078	63.2341995239258	5.17889976501465
30	8	62.4281005859375	63.1068992614746	5.13350009918213
30	9	67.1901016235352	64.1166000366211	6.69540023803711
30	10	82.3494033813477	66.317497253418	8.45310020446777
30	11	108.806999206543	70.1973037719727	7.90829992294312
30	12	120.821601867676	91.6664962768555	0
30	13	132.680206298828	117.369102478027	0
30	14	156.490905761719	125.271797180176	1.21169996261597
30	15	180.339706420898	134.109100341797	1.95220005512238
30	16	193.195297241211	144.341201782227	2.21690011024475
31	0	134.717803955078	138.567794799805	4.61980009078979
31	1	123.155502319336	129.927001953125	3.41039991378784
31	2	109.85710144043	120.116203308105	6.41319990158081
31	3	96.9499969482422	85.7669982910156	11.3240995407104
31	4	81.9807968139648	68.4108963012695	9.09609985351563
31	5	72.019401550293	65.897102355957	6.77720022201538
31	6	66.0820007324219	64.6061019897461	5.72529983520508
31	7	62.8517990112305	63.4625015258789	4.95319986343384
31	8	61.8838996887207	63.2877006530762	5.36140012741089
31	9	63.4361000061035	64.0414962768555	6.72910022735596
31	10	68.7043991088867	65.4720001220703	8.64400005340576
31	11	88.3828964233398	69.2240982055664	11.0062999725342
31	12	110.628898620605	88.627197265625	1.46720004081726
31	13	124.236297607422	109.073799133301	3.22189998626709
31	14	140.458206176758	124.607299804688	1.8183000087738
31	15	162.9833984375	134.293106079102	2.12649989128113
31	16	181.922897338867	143.004302978516	2.64120006561279
32	0	142.122299194336	140.119903564453	5.49160003662109
32	1	128.347198486328	130.531204223633	10.1534004211426
32	2	117.737602233887	120.694602966309	8.10249996185303
32	3	107.359298706055	74.1052017211914	12.4041996002197
32	4	94.6249008178711	67.8643035888672	9.29640007019043
32	5	79.6780014038086	65.8151016235352	7.10559988021851
32	6	71.2414016723633	64.1904983520508	5.86140012741089
32	7	66.4252014160156	63.3573989868164	5.18779993057251
32	8	63.7999992370605	63.0535011291504	5.77960014343262
32	9	63.4973983764648	63.2220001220703	5.83430004119873
32	10	66.2192993164063	64.9440994262695	7.76569986343384
32	11	74.5529022216797	68.5735015869141	9.96140003204346
32	12	99.8768997192383	74.5728988647461	1.64049994945526
32	13	114.390800476074	112.779296875	1.86710000038147
32	14	125.300300598145	126.615303039551	2.24670004844666
32	15	140.915893554688	132.114395141602	2.68569993972778
32	16	165.77099609375	142.417892456055	3.32439994812012`;
