import React from 'react';
import { PanelProps } from '@grafana/data';
import { FTHeatOptions, Threshold, DataType } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2, useTheme2 } from '@grafana/ui';
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { demo_data_tsv } from './test_data';

interface Props extends PanelProps<FTHeatOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      font-family: Open Sans;
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};

export const FTHeatPanel: React.FC<Props> = ({ options, data, width, height }) => {
  const theme = useTheme2();
  //#region Theme
  //ベースとなるテーマを取得
  const base_theme = getTheme();

  //Grafanaでテーブルに関連するCSSが上書きされるため再設定を行っている
  base_theme["Cell"] += `
  display: table-cell;
  `;
  base_theme["BaseCell"] += `
  padding: 0px;
  font-size: 0.6rem;
  text-align: center;
  background-color: ${theme.colors.background.primary};
  color: ${theme.colors.text.primary};
  display: table-cell;
  `;
  base_theme["HeaderCell"] += `
  background-color: ${theme.colors.background.secondary};
  color: ${theme.colors.text.secondary};
  `;
  base_theme["Row"] += `
  display: table-row;
  `;
  base_theme["BaseRow"] += `
  display: table-row;
  `;
  base_theme["HeaderRow"] += `
  display: table-row;
  `;
  base_theme["Table"] += `
  display: table;
  width: ${width}px;
  height: ${height}px;
  `;
  //#endregion
  const tableTheme = useTheme(base_theme);
  const styles = useStyles2(getStyles);
  
  //閾値の取得
  const firstThreshold = parseFloat(options.firstThreshold);
  const secondThreshold = parseFloat(options.secondThreshold);
  const thirdThreshold = parseFloat(options.thirdThreshold);
  const dataThreshold = parseFloat(options.threshold);
  
  //#region データ整形
  //複数のデータセットがあっても先頭のみを使用する
  const series = data.series[0];
  let angles: number[] = [];
  let height_positions: number[] = [];
  let defocus_positions: number[] = [];
  let prime_beam_diameters: number[] = [];
  let sub_beam_diameters: number[] = [];
  let side_peaks: number[] = [];
  if(series && options.useDemoData === false){
    let temp_angles: number[] = [];
    let temp_height_positions: number[] = [];
    let temp_defocus_positions: number[] = [];
    let temp_prime_beam_diameters: number[] = [];
    let temp_sub_beam_diameters: number[] = [];
    let temp_side_peaks: number[] = [];
    //振り角
    const angles_field = series.fields.find((field: any) => field.name === "angle");
    if(angles_field){
      temp_angles = angles_field.values.map((value: any) => parseFloat(value)) as number[];
    }
    //理論像高
    const height_positions_field = series.fields.find((field: any) => field.name === "height_position");
    if(height_positions_field){
      temp_height_positions = height_positions_field.values.map((value: any) => parseFloat(value)) as number[];
    }
    //位置
    const defocus_positions_field = series.fields.find((field: any) => field.name === "defocus_position");
    if(defocus_positions_field){
      temp_defocus_positions = defocus_positions_field.values.map((value: any) => parseFloat(value)) as number[];
    }
    //データ
    const prime_beam_diameters_field = series.fields.find((field: any) => field.name === "prime_beam_diameter");
    const sub_beam_diameters_field = series.fields.find((field: any) => field.name === "sub_beam_diameter");
    const side_peaks_field = series.fields.find((field: any) => field.name === "side_peak");
    if(prime_beam_diameters_field && sub_beam_diameters_field && side_peaks_field){
      temp_prime_beam_diameters = prime_beam_diameters_field.values.map((value: any) => parseFloat(value)) as number[];
      temp_sub_beam_diameters = sub_beam_diameters_field.values.map((value: any) => parseFloat(value)) as number[];
      temp_side_peaks = side_peaks_field.values.map((value: any) => parseFloat(value)) as number[];
    }

    //-999が存在するデータインデックスを見つける
    for (let i = 0; i < temp_prime_beam_diameters.length;i++){
      if(temp_prime_beam_diameters[i] !== -999 &&
        temp_sub_beam_diameters[i] !== -999 &&
        temp_side_peaks[i] !== -999){
          angles.push(temp_angles[i]);
          height_positions.push(temp_height_positions[i]);
          defocus_positions.push(temp_defocus_positions[i]);
          prime_beam_diameters.push(temp_prime_beam_diameters[i]);
          sub_beam_diameters.push(temp_sub_beam_diameters[i]);
          side_peaks.push(temp_side_peaks[i]);
      }
    }
  }
  else if (options.useDemoData){
    //test_data.tsvデータをロード
    const lines = demo_data_tsv.split("\n");
    angles.length = 0;
    height_positions.length = 0;
    defocus_positions.length = 0;
    prime_beam_diameters.length = 0;
    sub_beam_diameters.length = 0;
    side_peaks.length = 0;
    for(let n = 1; n < lines.length;n++){
      const cells = lines[n].split("\t");
      angles.push(n);
      height_positions.push(parseFloat(cells[0]));
      defocus_positions.push(parseFloat(cells[1]));
      prime_beam_diameters.push(parseFloat(cells[2]));
      sub_beam_diameters.push(parseFloat(cells[3]));
      side_peaks.push(parseFloat(cells[4]));
    }
    const defocus_length = [...new Set(defocus_positions)].length;
    for(let defocus = 0; defocus < defocus_positions.length; defocus++){
      defocus_positions[defocus] = (defocus_positions[defocus] -  Math.floor(defocus_length / 2));
    }
  }

  //像高のデータ長とでフォーカスのデータ長を取得する
  const height_length = [...new Set(height_positions)].length;
  const defocus_length = [...new Set(defocus_positions)].length;
  const defocus_columns: string[] = [];

  //デフォーカスのカラムは値をそのまま用いる
  for(let defocus = 0; defocus < defocus_length;defocus++){
    defocus_columns.push(defocus_positions[defocus].toFixed(1));
  }

  //ビームウェストを計算する
  const back_threshold: number[] = [];
  const front_threshold: number[] = [];
  const beam_weist: number[] = [];
  const beam_weist_diff: number[] = [];

  //表示するデータタイプによって取得データを反映する関数
  const getValue = (height: number, defocus: number, dataType: DataType) => {
    const index = height * defocus_length + defocus;
    if(dataType === "prime" && prime_beam_diameters !== undefined){
      return prime_beam_diameters[index];
    }else if(dataType === "sub" && sub_beam_diameters !== undefined){
      return sub_beam_diameters[index];
    }else if(dataType === "side_peak" && side_peaks !== undefined){
      return side_peaks[index];
    }
    return 0;
  }


  const rowData: any[] = [];
  for (let height = 0; height < height_length; height++) {
    const row: {[key: string]: any} = {};
    if(angles !== undefined){
      row["angle"] = angles[height * defocus_length].toFixed(1);
    }
    if(height_positions !== undefined){
      row["height_position"] = height_positions[height * defocus_length].toFixed(1);
    }
    let minHeader = "";
    let minValue = 1e10;
    let leftThresholdIndex = 0;
    let rightThresholdIndex = 0;
    for(let defocus = 0; defocus < defocus_length;defocus++){
      const header = defocus_columns[defocus];

      //値を取得する。ビームウェスト用に次のセルの値も取得する
      let nextValue = 0;
      const value = getValue(height, defocus, options.dataType);
      if(defocus < defocus_length - 1){
        nextValue = getValue(height, defocus + 1, options.dataType);
      }
      row[header] = value;

      if(value > 0){
        if(minValue > value){
          minValue = value;
          minHeader = header;
        }
      }
      let thresholdType: Threshold = "None";
      if(value < firstThreshold){
        thresholdType = "First";
      }else if(value < secondThreshold){
        thresholdType = "Second";
      }else if(value < thirdThreshold){
        thresholdType = "Third";
      }
      row[header + "_threshold"] = thresholdType;

      if(nextValue > 0){
        if(value > dataThreshold && dataThreshold >= nextValue){
          leftThresholdIndex = defocus;
        }
        if(value <= dataThreshold && dataThreshold < nextValue){
          rightThresholdIndex = defocus;
        }
      }
    }
    row["minHeader"] = minHeader;

    //ビームウェストの計算を行う
    //ビームウェストは跨いだセルを取得して、一時線形補間で位置を推定する
    const left_x1 = getValue(height, leftThresholdIndex, options.dataType);
    const left_x2 = getValue(height, leftThresholdIndex + 1, options.dataType);
    const left_y1 = defocus_positions[leftThresholdIndex];
    const left_y2 = defocus_positions[leftThresholdIndex + 1];

    const left_a = (left_y2 - left_y1) / (left_x2 - left_x1);
    const left_b = (left_x2 * left_y1 - left_x1 * left_y2) / (left_x2 - left_x1);
    const left_threshold = left_a * dataThreshold + left_b;
    back_threshold.push(left_threshold);
    row["back_threshold"] = left_threshold.toFixed(1);

    const right_x1 = getValue(height, rightThresholdIndex, options.dataType);
    const right_x2 = getValue(height, rightThresholdIndex + 1, options.dataType);
    const right_y1 = defocus_positions[rightThresholdIndex];
    const right_y2 = defocus_positions[rightThresholdIndex + 1];

    const right_a = (right_y2 - right_y1) / (right_x2 - right_x1);
    const right_b = (right_x2 * right_y1 - right_x1 * right_y2) / (right_x2 - right_x1);
    const right_threshold = right_a * dataThreshold + right_b;
    front_threshold.push(right_threshold);
    row["front_threshold"] = right_threshold.toFixed(1);

    beam_weist.push((left_threshold + right_threshold) / 2.0);
    row["beam_weist"] = beam_weist[height].toFixed(1);

    if(height > 0){
      beam_weist_diff.push(beam_weist[height] - beam_weist[height - 1]);
    }else{
      beam_weist_diff.push(0);
    }
    row["beam_weist_diff"] = beam_weist_diff[height].toFixed(1);
    
    rowData.push(row);
  }

  //react-table-libraryはnodesにデータが入る。
  const tableData = {nodes: rowData};
  //#endregion


  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
          overflow: auto;
        `
      )}
    >
    <Table data={tableData} theme={tableTheme} layout={{ custom: true }}>
      {(tableList: any) => (
        <>
        <Header>
          <HeaderRow>
            <HeaderCell>振り角</HeaderCell>
            <HeaderCell>理論像高</HeaderCell>
            {defocus_columns.map((column)=>(
              <HeaderCell key={column + "row"}>{column}</HeaderCell>
            ))}
            <HeaderCell>主前</HeaderCell>
            <HeaderCell>主後</HeaderCell>
            <HeaderCell>ウェスト</HeaderCell>
            <HeaderCell>差分</HeaderCell>
          </HeaderRow>
        </Header>
        <Body>
          {tableList.map((item: any)=>(
            <Row key={item["angle"]} item={item}>
              <Cell>{item["angle"]}</Cell>
              <Cell>{item["height_position"]}</Cell>
              {defocus_columns.map((column)=>{
                let style = {}
                if(item["minHeader"] === column){
                  style = { backgroundColor: theme.visualization.getColorByName(options.minimumColor) }
                }else if(item[column + "_threshold"] === "First") {
                  style = { backgroundColor: theme.visualization.getColorByName(options.firstColor) }
                }else if(item[column + "_threshold"] === "Second"){
                  style = { backgroundColor: theme.visualization.getColorByName(options.secondColor) }
                }else if(item[column + "_threshold"] === "Third"){
                  style = { backgroundColor: theme.visualization.getColorByName(options.thirdColor) }
                }
                return (<Cell key={column + "cell"} style={style}>{item[column].toFixed(1)}</Cell>);    
              })}
              <Cell>{item["back_threshold"]}</Cell>
              <Cell>{item["front_threshold"]}</Cell>
              <Cell>{item["beam_weist"]}</Cell>
              <Cell>{item["beam_weist_diff"]}</Cell>
            </Row>
          ))}
        </Body>
        </>
      )}
    </Table>
    </div>
  );
};
