import { PanelPlugin } from '@grafana/data';
import { FTHeatOptions as FTHeatOptions } from './types';
import { FTHeatPanel } from './components/FTHeatPanel';

export const plugin = new PanelPlugin<FTHeatOptions>(FTHeatPanel).setPanelOptions((builder) => {
  return builder
    .addBooleanSwitch({
      path: 'useDemoData',
      name: 'デモデータを使用',
      defaultValue: false,
    })
    .addTextInput({
      path: "threshold",
      name: "判定閾値",
      description: "ビームウェストの判定閾値を設定します",
      defaultValue: "65",
    })
    .addRadio({
      path: 'dataType',
      defaultValue: 'prime',
      name: 'データタイプ指定',
      settings: {
        options: [
          {
            value: 'prime',
            label: '主像面',
          },
          {
            value: 'sub',
            label: '副像面',
          },
          {
            value: 'side_peak',
            label: 'サイドピーク',
          },
        ],
      },
    })
    .addColorPicker({
      path: "minimumColor",
      name: "最小値カラー",
      defaultValue: "yellow",
    })
    .addTextInput({
      path: "firstThreshold",
      name: "第1閾値",
      description: "最も小さい閾値を設定します",
      defaultValue: "76"
    })
    .addColorPicker({
      path: "firstColor",
      name: "第1閾値カラー",
      description: "第1閾値を下回る際に表示される色です",
      defaultValue: "purple"
    })
    .addTextInput({
      path: "secondThreshold",
      name: "第2閾値",
      description: "2番目に小さい閾値を設定します",
      defaultValue: "80"
    })
    .addColorPicker({
      path: "secondColor",
      name: "第2閾値カラー",
      description: "第2閾値を下回る際に表示される色です",
      defaultValue: "cyan",
    })
    .addTextInput({
      path: "thirdThreshold",
      name: "第3閾値",
      description: "3番目に小さい閾値を設定します",
      defaultValue: "90",
    })
    .addColorPicker({
      path: "thirdColor",
      name: "第3閾値カラー",
      description: "第3閾値を下回る際に表示される色です",
      defaultValue: "green",
    })
});
